import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  headers: any;

  constructor(private http: HttpClient) {}

  // Método para obtener datos del servidor
  getData(relativeUrl: string): Observable<any> {
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
    });
    const url = environment.usersApiURL + relativeUrl;
    return this.http.get(url, { headers });
  }

  // Método para enviar datos al servidor
  sendData(relativeUrl: string, data?: any): Observable<any> {
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
    });
    const url = environment.usersApiURL + relativeUrl;
    return this.http.post<any>(url, data, { headers }).pipe(
      tap((response) => {}),
      catchError((error: HttpErrorResponse) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  /**
   * Método para actualizar datos al servidor con POST
   */
  updateData(relativeUrl: string, data: any) {
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
    });
    const url = environment.usersApiURL + relativeUrl;
    return this.http.post<any>(url, data, { headers }).pipe(
      tap((response) => {}),
      catchError((error: HttpErrorResponse) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  /**
   * Método para actualizar datos al servidor con PUT
   */
  updateDataPut(relativeUrl: string, data: any) {
    console.log(data);
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
    });
    const url = environment.usersApiURL + relativeUrl;
    return this.http.put<any>(url, data, { headers }).pipe(
      tap((response) => {}),
      catchError((error: HttpErrorResponse) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  /**
   * Metodo para eliminar datos del servidor
   * @param relativeUrl
   * @param data
   * @returns
   */
  deleteData(relativeUrl: string) {
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
    });
    const url = environment.usersApiURL + relativeUrl;
    return this.http.delete<any>(url, { headers }).pipe(
      tap((response) => {}),
      catchError((error: HttpErrorResponse) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  public setToken(token: any) {
    this.headers = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/x-www-form-urlencoded',
      }),
    };
  }

  public getAzureEnv() {
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
    });
    const url = environment.usersApiURL + '/api/checkMicrosoftEnv';
    return this.http.get(url, { headers });
  }
}
