import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { CompanyPageModule } from './pages/company/company.module';
import { UsersPageModule } from './pages/users/users.module';
import { DashboardPageModule } from './pages/dashboard/dashboard.module';
import { TokenGuard } from './guards/token.guard';
import { SectionsPageModule } from './pages/sections/sections.module';
import { DepartmentsPageModule } from './pages/departments/departments.module';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppLayoutComponent,
          children: [
            {
              path: '',
              loadChildren: () => DashboardPageModule,
              canActivate: [TokenGuard],
            },
            {
              path: 'companies',
              loadChildren: () => CompanyPageModule,
              canActivate: [TokenGuard],
            },
            {
              path: 'sections',
              loadChildren: () => SectionsPageModule,
              canActivate: [TokenGuard],
            },
            {
              path: 'departments',
              loadChildren: () => DepartmentsPageModule,
              canActivate: [TokenGuard],
            },
            {
              path: 'users',
              loadChildren: () => UsersPageModule,
              canActivate: [TokenGuard],
            },
            {
              path: 'dashboard',
              loadChildren: () => DashboardPageModule,
              canActivate: [TokenGuard],
            },
          ],
          canActivate: [TokenGuard],
        },
        {
          path: 'login',
          loadChildren: () =>
            import('./pages/login/login.module').then((m) => m.LoginPageModule),
        },
        {
          path: 'register',
          loadChildren: () =>
            import('./pages/register/register.module').then(
              (m) => m.RegisterPageModule
            ),
        },
        {
          path: 'reset-password',
          loadChildren: () =>
            import('./pages/reset-password/reset-password.module').then(
              (m) => m.ResetPasswordPageModule
            ),
        },
        { path: '**', redirectTo: 'notfound' },
      ],
      { preloadingStrategy: PreloadAllModules, useHash: true }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
