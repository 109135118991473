import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Utils } from 'src/app/app.utils';
import { Company } from 'src/app/models/company';
import { ApiService } from 'src/app/services/api.service';
import { Department } from 'src/app/models/department';
import { DatePipe, UpperCasePipe } from '@angular/common';
import { YesOrNo, departmentOptions, userTypes } from 'src/app/app.const';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { User } from 'src/app/models/user';
import { Employee } from 'src/app/models/employee';
import {
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  switchMap,
} from 'rxjs';

@Component({
  selector: 'app-companies',
  templateUrl: './company.page.html',
  styleUrls: ['./company.page.scss'],
})
export class CompanyPage implements OnInit {
  companyForm: FormGroup;
  departmentForm: FormGroup;
  companies: Company[] = [];
  departments: Department[] = [];
  loadingForm: boolean = false;
  loadingDepartments: boolean = false;
  loadingStep2: boolean = false;
  msgs: Message[] = [];
  formSubmitted: boolean = false;
  loading: boolean = true;
  dialogCompany: boolean = false;
  edit: boolean = false;
  uploadedFile: File | null = null;
  mainPhoto: File | undefined;
  mainStringPhoto: any;
  companySelected: Company;
  countries: any[] = [];
  yesOrNo = YesOrNo;
  departmentsByCompany: Department[] = [];
  dialogDepartments: boolean = false;
  employees: Employee[] = [];
  customers: any[] = [];
  associates: any[] = [];
  suppliers: any[] = [];
  filteredEmployees: any[] | undefined;
  departmentOptions = departmentOptions;
  departmentOptionSelected: string = 'LIST';
  departmentSelected: Department;
  dialogDepartmentEdit: boolean = false;
  dialogUserAssign: boolean = false;
  userTypes = userTypes;
  userTypeSelected: string = '';
  step1: boolean = false;
  step2: boolean = false;
  sourceUsers: any[] = [];
  targetUsers: any[] = [];
  users: User[] = [];
  usersWithoutRole: User[] = [];
  employeesForm: FormGroup;
  customersForm: FormGroup;
  suppliersForm: FormGroup;
  noneForm: FormGroup;
  auxiliarForm: FormGroup;
  dialogCompanyUsers: boolean = false;
  companyUsersByRole: any[] = [];
  selectedUser: User | null;
  employeeDepartmentSelected: number | null = null;
  auxiliarFormDefined: boolean = false;
  employeeData: any;

  constructor(
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private apiService: ApiService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.companyForm = this.fb.group({
      cif: [''],
      image: [''],
      company_name: ['', Validators.required],
      email: ['', Validators.email],
      fiscal_address: [''],
      fiscal_province: [''],
      fiscal_postal_code: [''],
      fiscal_country: [''],
      cnae: [''],
      phone: [''],
      start_activity: [''],
      employees_num: [''],
      number_millions: [''],
      sector: [''],
      business_figure: [],
      cross_sector_companies: [''],
      corporate_tax: [],
      social_capital: [''],
      officer_id: [''],
      level_weighting: [''],
      reevaluation: [''],
      document: [''],
      document_hash: [''],
    });

    this.departmentForm = this.fb.group({
      responsible: [''],
      main_activity: ['', Validators.required],
      date: [''],
      tasks_functions: [''],
      structure: [''],
      comments: [''],
      document: [''],
      document_hash: [''],
    });

    this.auxiliarForm = this.fb.group({
      department_id: [''],
      responsible: [''],
      entry_date: [''],
      address: [''],
      zip_code: [''],
      city: [''],
      province: [''],
      country: [''],
      criticality: [''],
      document: [''],
      document_hash: [''],
    });

    this.employeesForm = this.fb.group({
      records: this.fb.array([]),
    });

    this.employeesForm = this.fb.group({
      records: this.fb.array([]),
    });

    this.customersForm = this.fb.group({
      records: this.fb.array([]),
    });

    this.suppliersForm = this.fb.group({
      records: this.fb.array([]),
    });

    this.noneForm = this.fb.group({
      records: this.fb.array([]),
    });

    this.countries = Utils.getCountries();
    this.getCompanies();
  }

  get employeesRecords(): FormArray {
    return this.employeesForm.get('records') as FormArray;
  }

  get customersRecords(): FormArray {
    return this.customersForm.get('records') as FormArray;
  }

  get suppliersRecords(): FormArray {
    return this.suppliersForm.get('records') as FormArray;
  }

  get noneRecords(): FormArray {
    return this.noneForm.get('records') as FormArray;
  }

  /**
   * Añadir nuevo registro en el formulario de tipo de usuario
   */
  addRecord() {
    if (this.userTypeSelected === 'EMPLOYEE') {
      const record = this.fb.group({
        user_id: [null, Validators.required],
        department_id: [null, Validators.required],
      });
      this.employeesRecords.push(record);
    }

    if (this.userTypeSelected === 'SUPPLIER') {
      const record = this.fb.group({
        user_id: [null, Validators.required],
      });
      this.suppliersRecords.push(record);
    }

    if (this.userTypeSelected === 'CUSTOMER') {
      const record = this.fb.group({
        user_id: [null, Validators.required],
      });
      this.customersRecords.push(record);
    }

    if (this.userTypeSelected === 'NONE') {
      const record = this.fb.group({
        user_id: [null, Validators.required],
      });
      this.noneRecords.push(record);
    }
  }

  /**
   * Eliminar registro del formulario de roles y compañías/departamentos
   * @param index
   */
  removeRecord(index: number) {
    if (this.userTypeSelected === 'EMPLOYEE') {
      this.employeesRecords.removeAt(index);
    }

    if (this.userTypeSelected === 'CUSTOMER') {
      this.customersRecords.removeAt(index);
    }

    if (this.userTypeSelected === 'SUPPLIER') {
      this.suppliersRecords.removeAt(index);
    }

    if (this.userTypeSelected === 'NONE') {
      this.noneRecords.removeAt(index);
    }
  }

  /**
   * Obtener listado de empresas registradas
   */
  getCompanies() {
    this.apiService.getData('/api/companies').subscribe({
      next: (response) => {
        this.companies = response.data ? response.data : [];
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        console.error('Error al obtener empresas', error);
      },
    });
  }

  /**
   * Manejador de modal de creación/edición de empresas
   */
  async showCompanyDialog(company?: Company) {
    if (company) {
      this.companyForm.patchValue({
        ...company,
        start_activity: new Date(company.start_activity),
      });
      this.companySelected = company;
    }
    this.edit = company ? true : false;
    this.dialogCompany = true;
  }

  /**
   * Manejador para eliminar empresa
   * @param event
   * @param companyId
   */
  onDeleteClick(event, companyId: string) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmar eliminación',
      message: '¿Estás seguro de que deseas eliminar esta empresa?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.apiService.deleteData(`/api/companies/${companyId}`).subscribe({
          next: (response) => {
            if (response.data) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Empresa eliminada correctamente',
              });
              this.getCompanies();
            }
          },
          error: (error) => {
            console.error('Error eliminando la empresa', error);
          },
        });
      },
    });
  }

  /**
   * Manejador al cerrar modal de formulario
   */
  closeCompanyClose() {
    this.edit = false;
    this.companyForm.reset();
  }

  /**
   * Manejador para el selector de documentos
   * @param event
   */
  onFileSelect(event: any): void {
    const selectedFiles = event.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      this.uploadedFile = selectedFile;
    }
  }

  /**
   * Manejador de eliminación de archivo de evidencia
   * @param event
   */
  deleteFileSelected(event) {
    this.uploadedFile = null;
  }

  /**
   * Manejador para imágen principal
   * @param $event
   * @returns
   */
  public handleUploadPhoto($event: any) {
    try {
      const files: FileList = $event.target.files;
      this.mainPhoto = Utils.renameFile(
        files[0],
        new Date().getTime().toString()
      );
      var reader = new FileReader();
      if (
        this.mainPhoto.type === 'image/jpeg' ||
        this.mainPhoto.type === 'image/png'
      ) {
        reader.onloadend = async () => {
          $event.target.value = '';
          this.mainStringPhoto = String(reader.result);
        };
        reader.readAsDataURL(this.mainPhoto);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Eliminar foto adjunta al formulario
   */
  public deletePhoto() {
    this.mainPhoto = undefined;
    this.mainStringPhoto = undefined;
  }

  /**
   * Enviar formulario de empresa
   */
  public onSubmit() {
    this.companyForm.markAllAsTouched();

    if (this.companyForm.invalid) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Revise los campos del formulario',
      });
    }

    // Procesar formulario a FormData
    const formValue = this.companyForm.value;
    const formData = new FormData();

    // Tratar el campo 'entry_date' individualmente
    if (formValue.start_activity) {
      // Transformar la fecha usando DatePipe
      const formattedDate = this.datePipe.transform(
        formValue.start_activity,
        'yyyy-MM-dd'
      ); // Formato de ejemplo: 'yyyy-MM-dd'
      if (formattedDate) {
        formData.append('start_activity', formattedDate);
      }
    }

    // Recorrer los otros campos del formulario y agregar solo los que tienen valor
    for (const key in formValue) {
      if (
        formValue.hasOwnProperty(key) &&
        formValue[key] !== null &&
        formValue[key] !== '' &&
        key !== 'start_activity'
      ) {
        formData.append(key, formValue[key]);
      }
    }

    this.formSubmitted = true;
    this.loadingForm = true;

    // Enviamos datos para creación o edición
    if (this.edit) {
      this.apiService
        .updateData('/api/companies/' + this.companySelected.id, formData)
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Empresa actualizada correctamente',
              });
              this.loadingForm = false;
              this.dialogCompany = false;
              this.getCompanies();
            }
          },
          error: (error) => {
            this.loadingForm = false;
            console.error('Error al actualizar la empresa', error);
          },
        });
    } else {
      this.apiService.sendData('/api/companies', formData).subscribe({
        next: (response) => {
          if (response.data) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Empresa guardada correctamente',
            });
            this.loadingForm = false;
            this.dialogCompany = false;
            this.getCompanies();
          }
        },
        error: (error) => {
          this.loadingForm = false;
          console.error('Error al registrar la empresa', error);
        },
      });
    }
  }

  /**
   * Gestionar departamentos de una empresa seleccionada
   */
  async handleCompanyDepartments(company: Company) {
    this.dialogDepartments = true;
    this.companySelected = company;
    this.loadingDepartments = true;
    await this.getEmployees();
    await this.getDepartments(company);
  }

  /**
   * Obtener departamentos de una empresa
   */
  async getDepartments(company: Company) {
    try {
      const departmentsResponse = await lastValueFrom(
        this.apiService.getData(`/api/companies/${company.id}/departments`)
      );

      if (departmentsResponse) {
        this.departments = departmentsResponse.data
          ? departmentsResponse.data
          : [];
        this.loadingDepartments = false;
      }
    } catch (e) {
      this.loadingDepartments = false;
      console.error('Error al obtener los departamentos', e);
    }
  }

  /**
   * Filtro de usuarios empleados para desplegable
   * @param event
   */
  filterEmployee(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < (this.employees as any[]).length; i++) {
      let employee = (this.employees as any[])[i];
      if (employee.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(employee);
      }
    }
    this.filteredEmployees = filtered;
  }

  /**
   * Obtener listado de empleados guardados
   */
  async getEmployees() {
    try {
      const employeesResponse = await firstValueFrom(
        this.apiService.getData(
          `/api/companies/${this.companySelected.id}/employees`
        )
      );

      if (employeesResponse) {
        this.employees = employeesResponse.data ? employeesResponse.data : [];
      }
    } catch (e) {
      console.error('Error al obtener los empleados', e);
    }
  }

  /**
   * Obtener listado de empleados guardados
   */
  async getCustomers() {
    try {
      const customerResponse = await lastValueFrom(
        this.apiService.getData(
          `/api/companies/${this.companySelected.id}/customers`
        )
      );

      if (customerResponse) {
        this.customers = customerResponse.data ? customerResponse.data : [];
      }
    } catch (e) {
      console.error('Error al obtener los clientes', e);
    }
  }

  /**
   * Obtener listado de empleados guardados
   */
  async getSuppliers() {
    try {
      const suppliersResponse = await firstValueFrom(
        this.apiService.getData(
          `/api/companies/${this.companySelected.id}/suppliers`
        )
      );

      if (suppliersResponse) {
        this.suppliers = suppliersResponse.data ? suppliersResponse.data : [];
      }
    } catch (e) {
      console.error('Error al obtener los proveedores', e);
    }
  }

  /**
   * Obtener listado de empleados guardados
   */
  async getAssociates() {
    try {
      const associatesResponse = await lastValueFrom(
        this.apiService.getData(
          `/api/companies/${this.companySelected.id}/users/associates`
        )
      );

      if (associatesResponse) {
        this.associates = associatesResponse.data
          ? associatesResponse.data
          : [];
      }
    } catch (e) {
      console.error('Error al obtener los asociados', e);
    }
  }

  /**
   * Enviar formulario de departamento
   */
  onSubmitDepartment(editDepartment?: boolean) {
    this.departmentForm.markAllAsTouched();

    if (this.departmentForm.invalid) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Revise los campos del formulario',
      });
    }

    // Procesar datos de formulario de departamento
    const formValue = this.departmentForm.value;
    const formData = new FormData();

    this.appendIfDefined(
      formData,
      'responsible',
      formValue.responsible?.['employees'][0]?.id
    );
    this.appendIfDefined(formData, 'main_activity', formValue.main_activity);

    this.appendIfDefined(
      formData,
      'date',
      formValue.date,
      (date) => this.datePipe.transform(date, 'yyyy-MM-dd') ?? ''
    );

    this.appendIfDefined(
      formData,
      'tasks_functions',
      formValue.tasks_functions
    );
    this.appendIfDefined(formData, 'structure', formValue.structure);
    this.appendIfDefined(formData, 'comments', formValue.comments);

    if (formValue.document) {
      formData.append('document', formValue.document);
    }

    this.loadingForm = true;

    if (editDepartment) {
      this.apiService
        .updateData(
          `/api/companies/${this.companySelected.id}/departments/${this.departmentSelected.id}`,
          formData
        )
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Departamento guardado correctamente',
              });
              this.dialogDepartmentEdit = false;
              this.getDepartments(this.companySelected);
              this.loadingForm = false;
            }
          },
          error: (error) => {
            this.loadingForm = false;
            console.error('Error al crear el departamento', error);
          },
        });
    } else {
      this.apiService
        .sendData(
          `/api/companies/${this.companySelected.id}/departments`,
          formData
        )
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Departamento guardado correctamente',
              });
              this.dialogDepartments = false;
              this.getDepartments(this.companySelected);
              this.loadingForm = false;
            }
          },
          error: (error) => {
            this.loadingForm = false;
            console.error('Error al crear el departamento', error);
          },
        });
    }
  }

  /**
   * Manejador de cierre de modal de departamentos
   */
  closeDepartmentModal() {
    this.departmentForm.reset();
    this.departments = [];
    this.departmentOptionSelected = 'LIST';
  }

  /**
   * Función auxiliar para procesar formData de departamento
   */
  private appendIfDefined(
    formData: FormData,
    key: string,
    value: any,
    transformFn?: (value: any) => any
  ) {
    if (value !== undefined && value !== null) {
      formData.append(key, transformFn ? transformFn(value) : value.toString());
    }
  }

  /**
   * Manejador de modal para edición de departamento
   */
  showDepartmentEditDialog(department: Department) {
    this.dialogDepartmentEdit = true;
    if (department) {
      this.departmentSelected = department;
      this.departmentForm.patchValue({
        ...department,
        responsible: department.responsible
          ? department.responsible_department.user
          : null,
        date: department.date ? new Date(department.date) : null,
      });
    }
  }

  /**
   * Manejador de eliminación de departamento
   */
  onDeleteDepartmentClick(event, departmentId: number) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmar eliminación',
      message: '¿Estás seguro de que deseas eliminar este departamento?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.apiService
          .deleteData(
            `/api/companies/${this.companySelected.id}/departments/${departmentId}`
          )
          .subscribe({
            next: (response) => {
              if (response.data) {
                this.messageService.add({
                  severity: 'success',
                  summary: 'OK',
                  detail: 'Departamento eliminado correctamente',
                });
                this.getDepartments(this.companySelected);
              }
            },
            error: (error) => {
              console.error('Error eliminando el departamento', error);
            },
          });
      },
    });
  }

  /**
   * Manejador de cierre de modal de edición de departamento
   */
  closeDepartmentEditModal() {
    this.departmentForm.reset();
  }

  /**
   * Manejador de modal para asignar usuarios a empresas
   */
  handleUsersToCompany(company: Company) {
    this.dialogUserAssign = true;
    this.step1 = true;
    this.companySelected = company;
  }

  /**
   * Manejador de cierre de modal de asignación de usuarios a empresa
   */
  closeUserAssignModal() {
    this.step1 = false;
    this.step2 = false;
    this.userTypeSelected = '';
    this.employeesForm.reset();
    this.usersWithoutRole = [];
    this.resetAllForms();
  }

  /**
   * Reiniciar todos los formularios de roles
   */
  resetAllForms() {
    // Reiniciar employeesRecords
    while (this.employeesRecords.length) {
      this.employeesRecords.removeAt(0);
    }
    this.addRecord();
    this.employeesForm.reset({
      records: this.fb.array([]),
    });

    // Reiniciar customersRecords
    while (this.customersRecords.length) {
      this.customersRecords.removeAt(0);
    }
    this.addRecord();
    this.customersForm.reset({
      records: this.fb.array([]),
    });

    // Reiniciar supplierRecords
    while (this.suppliersRecords.length) {
      this.suppliersRecords.removeAt(0);
    }
    this.addRecord();
    this.suppliersForm.reset({
      records: this.fb.array([]),
    });

    // Reiniciar noneRecords
    while (this.noneRecords.length) {
      this.noneRecords.removeAt(0);
    }
    this.addRecord();
    this.noneForm.reset({
      records: this.fb.array([]),
    });
  }

  /**
   * Siguiente paso en asignación de usuarios a empresa
   */
  async nextStepAssignUser() {
    this.step1 = false;
    this.step2 = true;
    this.loadingStep2 = true;
    this.addRecord();
    this.getUserTypes();
  }

  /**
   * Obtenemos los usuarios que no tienen rol asignado aún
   */
  async getUserTypes() {
    if (this.userTypeSelected === 'EMPLOYEE') {
      try {
        await this.getUsers();
        await this.getEmployees();
        await this.getDepartments(this.companySelected);

        this.usersWithoutRole = this.users.filter(
          (user) => !this.employees.some((employee) => employee.id === user.id)
        );
      } catch (err) {
        this.loadingStep2 = false;
        console.error('Error al cargar usuarios o empleados', err);
      }
    }

    if (this.userTypeSelected === 'NONE') {
      try {
        await this.getUsers();
        await this.getAssociates();

        this.usersWithoutRole = this.users.filter(
          (user) =>
            !this.associates.some((associate) => associate.id === user.id)
        );
      } catch (err) {
        this.loadingStep2 = false;
        console.error('Error al cargar usuarios o empleados', err);
      }
    }

    if (this.userTypeSelected === 'CUSTOMER') {
      try {
        await this.getUsers();
        await this.getCustomers();

        this.usersWithoutRole = this.users.filter(
          (user) =>
            !this.customers.some((customer) => customer.user_id === user.id)
        );
      } catch (err) {
        this.loadingStep2 = false;
        console.error('Error al cargar usuarios o empleados', err);
      }
    }

    if (this.userTypeSelected === 'SUPPLIER') {
      try {
        await this.getUsers();
        await this.getSuppliers();

        this.usersWithoutRole = this.users.filter(
          (user) =>
            !this.suppliers.some((supplier) => supplier.user_id === user.id)
        );
      } catch (err) {
        this.loadingStep2 = false;
        console.error('Error al cargar usuarios o empleados', err);
      }
    }

    setTimeout(() => {
      this.loadingStep2 = false;
    }, 500);
  }

  /**
   * Comprobar duplicidad de user_id antes de enviar datos de formulario
   */
  checkForDuplicateUserIds(
    records: Array<{ user_id: number; department_id: number }>
  ): boolean {
    const userIdCountMap = new Map<number, number>();

    for (const record of records) {
      const userId = record.user_id;
      if (userIdCountMap.has(userId)) {
        userIdCountMap.set(userId, userIdCountMap.get(userId)! + 1);
      } else {
        userIdCountMap.set(userId, 1);
      }
    }

    for (const count of userIdCountMap.values()) {
      if (count > 1) {
        return true; // Hay al menos un user_id duplicado
      }
    }

    return false; // No hay user_id duplicados
  }

  /**
   * Enviar formulario con usuarios de empresa para asignar
   */
  onSubmitCompanyUsers() {
    this.employeesForm.markAllAsTouched();

    // Empleados
    if (this.userTypeSelected === 'EMPLOYEE') {
      if (this.employeesForm.invalid) {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Revise los campos del formulario',
        });
      }

      const data = this.employeesForm.value;
      const hasDuplicates = this.checkForDuplicateUserIds(data.records);

      if (hasDuplicates) {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail:
            'Revise los datos, un mismo usuario no puede pertenecer a varios departamentos',
        });
      }

      this.loadingForm = true;

      this.apiService
        .sendData(
          `/api/companies/${this.companySelected.id}/employees`,
          data.records
        )
        .subscribe({
          next: (response) => {
            if (response[0].data) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Empleados guardados correctamente',
              });
              this.loadingForm = false;
              this.dialogUserAssign = false;
            }
          },
          error: (error) => {
            this.loadingForm = false;
            console.error('Error al guardar el empleado', error);
          },
        });
    }

    // Proveedores
    if (this.userTypeSelected === 'SUPPLIER') {
      if (this.suppliersForm.invalid) {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Revise los campos del formulario',
        });
      }

      const data = this.suppliersForm.value;
      const hasDuplicates = this.checkForDuplicateUserIds(data.records);

      if (hasDuplicates) {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail:
            'Revise los datos, no puede seleccionar un usuario más de una vez',
        });
      }

      this.loadingForm = true;

      this.apiService
        .sendData(
          `/api/companies/${this.companySelected.id}/suppliers`,
          data.records
        )
        .subscribe({
          next: (response) => {
            console.log(response);
            if (response[0].data) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Proveedores guardados correctamente',
              });
              this.loadingForm = false;
              this.dialogUserAssign = false;
            }
          },
          error: (error) => {
            this.loadingForm = false;
            console.error('Error al guardar los proveedores', error);
          },
        });
    }

    // Clientes
    if (this.userTypeSelected === 'CUSTOMER') {
      if (this.customersForm.invalid) {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Revise los campos del formulario',
        });
      }

      const data = this.customersForm.value;
      const hasDuplicates = this.checkForDuplicateUserIds(data.records);

      if (hasDuplicates) {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail:
            'Revise los datos, no puede seleccionar un usuario más de una vez',
        });
      }
      this.loadingForm = true;

      this.apiService
        .sendData(
          `/api/companies/${this.companySelected.id}/customers`,
          data.records
        )
        .subscribe({
          next: (response) => {
            console.log(response);
            if (response[0].data) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Clientes guardados correctamente',
              });
              this.loadingForm = false;
              this.dialogUserAssign = false;
            }
          },
          error: (error) => {
            this.loadingForm = false;
            console.error('Error al guardar los clientes', error);
          },
        });
    }

    // Ningun rol
    if (this.userTypeSelected === 'NONE') {
      if (this.noneForm.invalid) {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Revise los campos del formulario',
        });
      }

      const data = this.noneForm.value;
      const hasDuplicates = this.checkForDuplicateUserIds(data.records);

      if (hasDuplicates) {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail:
            'Revise los datos, no puede seleccionar un usuario más de una vez',
        });
      }

      const usersIdData = JSON.stringify(
        data.records.map((item) => item.user_id)
      );

      this.loadingForm = true;

      this.apiService
        .sendData(
          `/api/companies/${this.companySelected.id}/users/${usersIdData}`
        )
        .subscribe({
          next: (response) => {
            console.log(response);
            if (response[0].data) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Usuarios asignados a empresa correctamente',
              });
              this.loadingForm = false;
              this.dialogUserAssign = false;
            }
          },
          error: (error) => {
            this.loadingForm = false;
            console.error('Error al guardar la asignación a empresas', error);
          },
        });
    }
  }

  /**
   * Obtener usuarios registrados
   */
  async getUsers() {
    try {
      const usersResponse = await firstValueFrom(
        this.apiService.getData('/api/users')
      );
      if (usersResponse.data) {
        this.users = usersResponse.data;
      } else {
        this.users = [];
      }
    } catch (err) {
      console.log('Error al obtener listado completo de usuarios', err);
    }
  }

  /**
   * Manejador de modal para listar usuarios de una empresa, dado un rol
   */
  viewCompanyUsers(company: Company) {
    this.dialogCompanyUsers = true;
    this.companySelected = company;
    this.step1 = true;
  }

  /**
   * Manejador de cierre de modal de listado y gestión de usuarios de la empresa
   */
  closeViewCompanyUsers() {
    this.step1 = false;
    this.step2 = false;
    this.userTypeSelected = '';
    this.selectedUser = null;
    this.employeeDepartmentSelected = null;
    this.auxiliarFormDefined = false;
  }

  /**
   * Enviar formulario de actualización de datos de usuario y su rol
   */
  updateCompanyUsers() {
    const roleId =
      this.userTypeSelected === 'EMPLOYEE'
        ? this.employeeData.id
        : this.selectedUser?.id;

    // Procesar datos del formulario
    this.auxiliarForm.markAllAsTouched();
    const formValue = this.auxiliarForm.value;
    const formData = new FormData();

    formData.append('department_id', formValue.department_id);
    formData.append('responsible', formValue.responsible);
    if (formValue.entry_date) {
      const dateValue = this.datePipe.transform(
        formValue.entry_date,
        'yyyy-MM-dd'
      );
      formData.append('entry_date', dateValue!.toString());
    }
    formData.append(
      'address',
      formValue.address ? formValue.address.toString : ''
    );
    formData.append('city', formValue.city ? formValue.city.toString() : '');
    formData.append(
      'province',
      formValue.province ? formValue.province.toString() : ''
    );
    formData.append(
      'country',
      formValue.country ? formValue.country.toString() : ''
    );
    formData.append(
      'criticality',
      formValue.criticality ? formValue.criticality.toString() : ''
    );
    if (formValue.document) {
      formData.append('document', formValue.document);
    }
    const userTypeUrl =
      this.userTypeSelected === 'CUSTOMER'
        ? 'customers'
        : this.userTypeSelected === 'SUPPLIER'
        ? 'suppliers'
        : this.userTypeSelected === 'EMPLOYEE'
        ? 'employees'
        : 'undefined';

    this.loadingForm = true;

    // Enviar datos al API
    this.apiService
      .updateData(
        `/api/companies/${this.companySelected.id}/${userTypeUrl}/${roleId}`,
        formData
      )
      .subscribe({
        next: (response) => {
          if (response.data) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Datos actualizados correctamente',
            });
            this.dialogCompanyUsers = false;
            this.loadingForm = false;
          }
        },
        error: (err) => {
          this.loadingForm = false;
          console.log('Error al actualizar los datos del usuario', err);
        },
      });
  }

  /**
   * Siguiente paso en listar usuarios según rol, previa selección de rol
   */
  nextStepViewCompanyUsers() {
    this.step1 = false;
    this.step2 = true;
    this.loadingStep2 = true;
    let typeSelected: string = '';
    // Controlamos que rol se ha seleccionado
    if (this.userTypeSelected === 'EMPLOYEE') {
      typeSelected = 'employees';
    }
    if (this.userTypeSelected === 'CUSTOMER') {
      typeSelected = 'customers';
    }
    if (this.userTypeSelected === 'SUPPLIER') {
      typeSelected = 'suppliers';
    }

    if (this.userTypeSelected !== 'NONE') {
      this.apiService
        .getData(
          '/api/companies/' + this.companySelected?.id + '/' + typeSelected
        )
        .subscribe({
          next: (response) => {
            this.companyUsersByRole = response.data ? response.data : [];
            this.loadingStep2 = false;
          },
          error: (err) => {
            console.error(
              'Error al obtener los datos de usuarios de la empresa',
              err
            );
          },
        });
    } else {
      this.apiService
        .getData(`/api/companies/${this.companySelected.id}/users/associates`)
        .subscribe({
          next: (response) => {
            this.companyUsersByRole = response.data ? response.data : [];
            this.loadingStep2 = false;
          },
          error: (err) => {
            console.error(
              'Error al obtener usuarios sin rol de esta empresa',
              err
            );
          },
        });
    }
  }

  /**
   * Manejador para mostrar el formulario de edición del usuario seleccionado en el modal ya abierto
   * @param user
   */
  async showSubModalUpdate(user: User) {
    const userResponse = await lastValueFrom(
      this.apiService.getData('/api/users')
    );

    if (userResponse) {
      this.users = userResponse.data;
      this.assignUser(user);
    }
  }

  assignUser(user: User): any {
    this.selectedUser = user;
    if (
      this.userTypeSelected === 'CUSTOMER' ||
      this.userTypeSelected === 'SUPPLIER'
    ) {
      this.auxiliarFormDefined = true;
    }
    console.log(this.selectedUser);
  }

  /**
   * Manejador de formulario auxiliar de empleados, con departamento seleccionado
   * @param event
   */
  handleEmployeeDepartmentData(event) {
    this.employeeData = event;
    this.getDepartments(this.companySelected);
    this.auxiliarForm.patchValue(this.employeeData);
    this.auxiliarFormDefined = true;
  }

  /**
   * Eliminar usuario asignado a rol
   * @param user
   */
  async deleteUserByRol(event, user: any) {
    let typeSelected: string = '';
    let typeSelectedLabel: string = '';
    let roleId: number;
    let userRoleSelected: any[] = [];

    try {
      const userResponse = await lastValueFrom(
        this.apiService.getData(
          `/api/users/${user.user_id ? user.user_id : user.id}`
        )
      );

      if (userResponse.data) {
        if (this.userTypeSelected === 'EMPLOYEE') {
          typeSelected = 'employees';
          typeSelectedLabel = 'empleado';
          userRoleSelected = userResponse.data['employees'].filter(
            (item) => item.department.company_id === this.companySelected.id
          );
          roleId = userRoleSelected[0].id;
        }

        if (this.userTypeSelected === 'CUSTOMER') {
          typeSelected = 'customers';
          typeSelectedLabel = 'cliente';
          userRoleSelected = userResponse.data['customers'].filter(
            (item) => item.company_id === this.companySelected.id
          );
          roleId = userRoleSelected[0].id;
        }

        if (this.userTypeSelected === 'NONE') {
          typeSelectedLabel = 'usuario asociado';
        }

        if (this.userTypeSelected === 'SUPPLIER') {
          typeSelected = 'suppliers';
          typeSelectedLabel = 'proveedor';
          userRoleSelected = userResponse.data['suppliers'].filter(
            (item) => item.company_id === this.companySelected.id
          );
          roleId = userRoleSelected[0].id;
        }
      }
    } catch (error) {
      console.error('Error al obtener datos del usuario', error);
    }

    // Dialogo de eliminación
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmar eliminación',
      message:
        '¿Estás seguro de que deseas eliminar este ' + typeSelectedLabel + '?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        // Si no es usuario asociado
        if (this.userTypeSelected === 'NONE') {
          this.apiService
            .deleteData(
              `/api/companies/${this.companySelected.id}/users/${user.id}`
            )
            .subscribe({
              next: (response) => {
                if (response.data) {
                  this.messageService.add({
                    severity: 'success',
                    summary: 'OK',
                    detail:
                      typeSelectedLabel[0].toUpperCase() +
                      typeSelectedLabel.slice(1) +
                      ' eliminado correctamente',
                  });
                }
                this.dialogCompanyUsers = false;
              },
              error: (error) => {
                console.error('Error eliminando el usuario', error);
              },
            });
        } else {
          //Si tiene algún rol asociado
          this.apiService
            .deleteData(
              '/api/companies/' +
                this.companySelected.id +
                '/' +
                typeSelected +
                '/' +
                roleId
            )
            .subscribe({
              next: (response) => {
                if (response.data) {
                  this.messageService.add({
                    severity: 'success',
                    summary: 'OK',
                    detail:
                      typeSelectedLabel[0].toUpperCase() +
                      typeSelectedLabel.slice(1) +
                      ' eliminado correctamente',
                  });
                }
                this.dialogCompanyUsers = false;
              },
              error: (error) => {
                console.error('Error eliminando el usuario', error);
              },
            });
        }
      },
    });
  }

  /**
   * Manejador de selector de gestión de tipos de usuario de una empresa
   */
  handleRoleUserByCompany(event, company) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      icon: 'pi pi-exclamation-triangle',
      message: 'Seleccione una opción',
      acceptLabel: 'Añadir usuario a la empresa',
      rejectLabel: 'Ver usuarios de la empresa',
      accept: () => {
        this.handleUsersToCompany(company);
      },
      reject: () => {
        this.viewCompanyUsers(company);
      },
    });
  }
}
