export const userTypes = [
  {
    label: 'Cliente',
    value: 'CUSTOMER',
  },

  {
    label: 'Proveedor',
    value: 'SUPPLIER',
  },
  {
    label: 'Empleado',
    value: 'EMPLOYEE',
  },
  {
    label: 'Asociado',
    value: 'NONE',
  },
];

export const YesOrNo = [
  {
    label: 'Sí',
    value: 1,
  },
  {
    label: 'No',
    value: 0,
  },
];

export const departmentOptions = [
  {
    label: 'Nuevo departamento',
    value: 'NEW',
  },
  {
    label: 'Departamentos existentes',
    value: 'LIST',
  },
];

export const roleTypes = [
  {
    label: 'Administrador',
    value: 'Administrador',
  },

  {
    label: 'Reportador',
    value: 'Reportador',
  },
  {
    label: 'Auditor',
    value: 'Auditor',
  },
];
